<template>
  <b-card
    img-top
    class="ecommerce-card offer"
    no-body
  >
    <div class="offer-image-wrapper">
      <b-img-lazy
        :alt="`${offer.name}-${offer.id}`"
        fluid
        class="card-img-top"
        :src="getFirstGalleryImage(offer)"
        @error.native="$event.target.src=defaultImage"
      />
    </div>

    <ProfileAvatar
      :user="offer.user"
      avatar-size="114"
      variant="light-secondary"
    />

    <b-card-body>
      <b-card-text class="text-center pt-1">
        <h5>{{ offer.user.name }}</h5>
      </b-card-text>
      <div class="d-flex justify-content-between  align-items-center">
        <profile-languages :user="offer.user" />
        <profile-rating />
      </div>
      <b-card-text>
        <offer-about
          :about-data="offer"
          :short="true"
        />
      </b-card-text>

    </b-card-body>

    <!-- Product Actions -->
    <div class="item-options text-center ">
      <div class="item-wrapper" />
      <!--            <b-button-->
      <!--              variant="light"-->
      <!--              tag="a"-->
      <!--              class="btn-wishlist"-->
      <!--              @click="toggleProductInWishlist(user)"-->
      <!--            >-->
      <!--              <feather-icon-->
      <!--                icon="HeartIcon"-->
      <!--                class="mr-50"-->
      <!--                :class="{'text-danger': user.isInWishlist}"-->
      <!--              />-->
      <!--              <span>Wishlist</span>-->
      <!--            </b-button>-->
      <b-button
        variant="primary"
        tag="a"
        class="btn-cart"
        @click="handleUserViewActionClick(offer.user)"
      >
        <feather-icon
          icon="EyeIcon"
          class="mr-50"
        />
        <span>{{ $t('Read more') }}</span>
      </b-button>

    </div>
    <!--/ follower projects rank -->
  </b-card>
</template>
<script>
import {
  BCard, BAvatar, BBadge, BButton, BCardText, BCardBody, BImgLazy,
} from 'bootstrap-vue'
import ProfileLanguages from '@/views/pages/profile/ProfileLanguages.vue'
import ProfileAvatar from '@/views/pages/profile/ProfileAvatar.vue'
import ProfileRating from '@/views/pages/profile/ProfileRating.vue'
import { getUserAvatarText } from '@/mixins/functions'
import { useUserUi } from '@/views/apps/user/useUser'
import OfferAbout from '@/views/apps/service/offer/details/OfferAbout.vue'

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
    BButton,
    BCardText,
    BCardBody,
    BImgLazy,
    ProfileAvatar,
    ProfileLanguages,
    ProfileRating,
    OfferAbout,
  },
  mixins: [getUserAvatarText],
  props: {
    offer: {
      type: Object,
      required: true,
    },
    itemView: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      handleUserViewActionClick,
    } = useUserUi()

    const defaultImage = require('@/assets/images/svg/service-noimage.svg')
    const getFirstGalleryImage = point => (point.media_files !== undefined && point.media_files.length ? point.media_files[0].url : defaultImage)
    return {
      handleUserViewActionClick,
      getFirstGalleryImage,
      defaultImage,
    }
  },
}
</script>
<style lang="scss">
.ecommerce-card.offer .profile-image{
  position: absolute!important;
  top: 9rem!important;
}
.ecommerce-card.offer .profile-image-wrapper{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.b-avatar.badge-light-secondary {
  background-color: rgba(240, 240, 241, 1);
}
.offer-image-wrapper{
  height: 14rem;
  overflow: hidden;
  opacity: 0.10;
}
</style>
